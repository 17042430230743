<template>
  <div>
    <a-drawer
      title="资讯-详情"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="detailClose"
    >
      <div class="drawer-content">
        <div class="content-title">
          {{ detailData.title }}
          <a-tag color="blue">{{
            detailData.status === 1 ? "未发布" : "已发布"
          }}</a-tag>
        </div>
        <a-divider></a-divider>

        <a-descriptions>
          <a-descriptions-item label="资讯类型">
            {{ detailData.categoryName }}
          </a-descriptions-item>
          <a-descriptions-item label="阅读量">
            {{ detailData.viewsNum }}
          </a-descriptions-item>
          <a-descriptions-item label="创建人">
            {{ detailData.createName }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ detailData.createDate }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1">
          <a-descriptions-item label="资讯内容">
            {{ detailData.content }}
          </a-descriptions-item>
          <a-descriptions-item label="公告封面图">
            <img
              v-for="(item, index) in detailData.imgList"
              :key="index"
              :src="$ImgUrl(item.url)"
              class="content-img"
            />
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="detailClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { newsInfo } from "@/api/operation/news/index.js";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		detailId: Number,
	},
	data() {
		return {
			detailData: {},
		};
	},
	mounted() {},
	methods: {
		detailClose() {
			this.$emit("detailClose");
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
	},
	watch: {
		detailId: {
			handler(id) {
				if (id !== null && id !== undefined) {
					newsInfo({ informationId: id }).then((res) => {
						this.detailData = res.data;
					});
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="less" scoped>
.content-title {
  color: #000000d9;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.content-img {
  width: 108px;
  height: 86px;
}
</style>
