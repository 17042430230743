import httpService from "@/request"

// 资讯
export function newsList(params) {
	return httpService({
		url: `/user/information/list`,
		method: 'get',
		params: params,
	})
}
export function newsInfo(params) {
	return httpService({
		url: `/user/information/findById`,
		method: 'get',
		params: params,
	})
}
export function itemSelect(params) {
	return httpService({
		url: `/user/informationCategory/allList`,
		method: 'get',
		params: params,
	})
}
export function newsUpdate(params) {
	return httpService({
		url: `/user/information/update`,
		method: 'post',
		data: params,
	})
}
export function newsDel(params) {
	return httpService({
		url: `/user/information/delete`,
		method: 'post',
		data: params,
	})
}
export function newsInsert(params) {
	return httpService({
		url: `/user/information/insert`,
		method: 'post',
		data: params,
	})
}