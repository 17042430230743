export const form = {
	id:undefined,
	title:undefined,
	categoryId:undefined,
	status:undefined,
	content:'',
	imgUrls:[]
}
export const rules = {
	title:[{required:true,message:'请输入标题',trigger:'blur'}],
	categoryId:[{required:true,message:'请选择分类',trigger:'change'}],
	status:[{required:true,message:'请选择',trigger:'change'}],
	content:[{required:true,message:'请输入',trigger:'blur'}],
}
export const options = {
	status:[
		{ id:1, name:'未发布' },
		{ id:2, name:'已发布' },
	],
	categoryId:[]
}