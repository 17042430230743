<template>
  <div>
    <div class="cardTitle">资讯管理</div>
    <searchForm :formItem="formItem" @getSearch="search($event)" @changeTime='changeTime'></searchForm>
    <a-button style="margin: 10px" class="add-btn" @click="drawer.show = true"
      >新增资讯</a-button
    >
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="detail(row.id)">查看详情</a>
          <a class="ant-dropdown-link" @click="edit(row.id)">修改</a>
          <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
        </a-space>
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="del"> 批量删除 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <addForm
      :show="drawer.show"
      @success="success"
      @addClose="addClose"
      :editId="editId"
    ></addForm>
	<detailForm :detailId="detailId" :show="drawer.detail"  @detailClose="detailClose"></detailForm>
  </div>
</template>

<script>
import { newsList, newsDel,itemSelect } from "@/api/operation/news/index.js";
import { formItem, columns, pagination } from "./depend/config";
import addForm from "./depend/form.vue";
import detailForm from "./depend/detail.vue";
export default {
	components: {
		addForm,
		detailForm
	},
	data() {
		return {
			drawer: {
				show: false,
				detail:false
			},
			editId: null,
			detailId:null,
			tableData: [],
			searchForm: {
				activityAddress: undefined,
				organizerName: undefined,
				activityContact: undefined,
				activityTel: undefined,
				title: undefined,
				status: undefined,
				registrationStartTime: undefined,
				registrationEndTime: undefined,
				activityStartTime: undefined,
				activityEndTime: undefined,
			},
			formItem,
			columns,
			pagination,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getData();
		this.getItem();
	},
	methods: {
		async getData() {
			let res = await newsList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys=[]
		},
		search(data){
			this.searchForm = data;
			this.getData()
		},
		async getItem(){
			let res = await itemSelect()
			this.formItem[0].option = res.data
		},
		changeTime(data,start,end){
			this.searchForm[start] = data[0]
			this.searchForm[end] = data[1]
		},
		edit(id) {
			this.editId = id;
			this.drawer.show = true;
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async () => {
					let res = await newsDel({ informationIds: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.selectedRowKeys);
			}
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		addClose() {
			this.drawer.show = false;
			this.editId = null;
		},
		success() {
			this.getData();
		},
		detail(id){
			this.drawer.detail = true;
			this.detailId = id;
		},
		detailClose(){
			this.detailId = null;
			this.drawer.detail = false;
		}
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
</style>
