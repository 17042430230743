<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item prop="title" label="标题">
                <a-input
                  v-model="form.title"
                  placeholder="请输入标题"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="categoryId" label="资讯分类">
                <a-select
                  v-model="form.categoryId"
                  placeholder="请选择"
                  style="width: 80%"
                >
                  <a-select-option
                    v-for="item in options.categoryId"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="status" label="发布状态">
                <a-select
                  v-model="form.status"
                  placeholder="请选择"
                  style="width: 80%"
                >
                  <a-select-option
                    v-for="item in options.status"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item prop="content" label="内容">
                <a-textarea
                  v-model="form.content"
                  placeholder="请输入内容，不超过1024字"
                  style="width: 80%"
				  :autosize="{minRows: 10}"
                ></a-textarea>
				<div>
                    已输入 {{form.content.length}} / 1024 字
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              资讯封面图片
              <commonUpload
                :fileList="fileList"
                @handleChange="handleChange"
              ></commonUpload>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import {
	newsInsert,
	newsUpdate,
	newsInfo,
	itemSelect
} from "@/api/operation/news/index.js";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "新增资讯",
			form,
			rules,
			options,
			fileList: [],
		};
	},
	mounted() {
		this.getItem();
	},
	methods: {
		async getItem() {
			let res = await itemSelect();
			this.options.categoryId = res.data;
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.fileList = [];
			this.form.imgUrls = [];
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editId === null) {
						let res = await newsInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await newsUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
		handleChange(data) {
			this.fileList = data;
			this.form.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgUrls.push(ele.response.data)
				}
			})
		},
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.title = "修改资讯";
					this.form.id = val;
					newsInfo({ informationId: val }).then((res) => {
						this.form = res.data;
						this.form.imgUrls = []
						if (res.data.imgList.length > 0) {
							const pic = [];
							this.form.imgUrls.push(res.data.imgList[0].url);
							for (let item of res.data.imgList) {
								let obj = {
									name: item.url.split("_")[0] + "." + item.url.split(".")[1],
									url: this.$ImgUrl(item.url),
									uid: item.url.split("_")[1],
									status: "done",
									thumbUrl: this.$ImgUrl(item.url),
								};
								pic.push(obj);
							}
							this.fileList = pic;
						} else {
							this.form.imgUrls = [];
						}
					});
				} else {
					this.title = "新增资讯";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
