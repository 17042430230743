export const formItem = [
	{
		type: 'select',
		label:'资讯分类',
		prop:'categoryId',
		option:[],
		placeholder:'请选择'
	},
	{
		type: 'select',
		label:'发布状态',
		prop:'status',
		option:[{ id:1,name:'未发布'},{ id:2,name:'已发布'},],
		placeholder:'请选择状态'
	},
	{
		type: 'time',
		label: '创建时间',
		prop:'create',
		start: 'createStartDate',
		end:'createEndDate',
	},
	{
		type: 'input',
		label:'标题',
		prop:'title',
		placeholder:'请输入'
	},
]
export const columns = [
	
	{
		title: "标题",
		dataIndex: "title",
		width: 340,
	},
	{
		title: "分类",
		dataIndex: "categoryName",
		width: 145,
	},
	{
		title: "发布状态",
		dataIndex: "status",
		width: 145,
		customRender: function (status) {
			switch (status) {
			case 1:
				return '未发布'
			case 2:
				return '已发布'
			}
		}
	},
	{
		title: "阅读量",
		dataIndex: "viewsNum",
		width: 145,
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
		width: 220,
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}